var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"af-modal-card",class:_vm.brand,style:({"width":"650px"})},[_c('div',{staticClass:"modal-card__content"},[(_vm.isEmailExists && !_vm.newCodeSent)?_c('div',[_c('p',{staticClass:"has-text-weight-bold is-size-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("af:applications.email_confirmation_modal.email_exist.title"))+" ")]),_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'af:applications.email_confirmation_modal.email_exist.subtitle',
              {
                email: _vm.email,
              }
            )
          )}})]),_c('p',{staticClass:"has-text-weight-bold has-text-centered mt-5"},[_c('b-button',{attrs:{"type":"is-blue"},on:{"click":_vm.sendNewCode}},[_vm._v(" "+_vm._s(_vm.$t( "af:applications.email_confirmation_modal.email_exist.button.send_new_code" )))])],1),_c('div',{staticClass:"has-text-centered"},[_c('b-button',{staticClass:"mt-4",attrs:{"type":"is-text"},on:{"click":_vm.refreshPage}},[_vm._v(" "+_vm._s(_vm.$t( "af:applications.email_confirmation_modal.confirmed_on_another_device.button" ))+" ")])],1),(!_vm.changeEmail)?_c('div',[_c('p',{staticClass:"has-text-weight-bold has-text-centered mt-3"},[_c('span',{staticClass:"af-text-button has-text-blue",on:{"click":function($event){_vm.changeEmail = true}}},[_vm._v(" "+_vm._s(_vm.$t( "af:applications.email_confirmation_modal.email_exist.button.change_email" )))])])]):_vm._e()]):_vm._e(),(!_vm.isEmailExists && !_vm.newCodeSent)?_c('div',[_c('p',{staticClass:"has-text-weight-bold is-size-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("af:applications.email_confirmation_modal.email_not_exist.title"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "af:applications.email_confirmation_modal.email_not_exist.subtitle" ))+" ")])]):_vm._e(),(_vm.changeEmail && !_vm.newCodeSent)?_c('div',{staticClass:"mt-5"},[_c('form',{attrs:{"autocomplete":"new-password"}},[_c('validation-observer',{ref:"observer"},[_c('BInputWithValidation',{attrs:{"rules":"required|email","label":_vm.$t(
                'af:applications.email_confirmation_modal.change_email.email.label'
              ),"label-position":"on-border","name":"application-email"},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var validated = ref.validated;
              var valid = ref.valid;
return [_c('b-field',{staticClass:"mb-5",attrs:{"label":_vm.$t(
                  'af:applications.email_confirmation_modal.change_email.password.label'
                ),"label-position":"on-border","name":"application-code","type":{
                'is-danger': errors[0],
                'is-success': validated && valid,
              },"message":errors}},[_c('b-input',{attrs:{"type":"password","autocomplete":"new-password","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,false,966053301)})],1)],1),_c('p',{staticClass:"has-text-weight-bold has-text-centered mt-5"},[_c('b-button',{attrs:{"type":"is-blue","id":"emailConfirmationModalSendNewCode"},on:{"click":_vm.sendNewCodeToNewEmail}},[_vm._v(" "+_vm._s(_vm.$t( "af:applications.email_confirmation_modal.change_email.button.submit" ))+" ")])],1)]):_vm._e(),(_vm.newCodeSent)?_c('div',[_c('p',{staticClass:"has-text-weight-bold is-size-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("af:applications.email_confirmation_modal.email_sent.title"))+" ")]),_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'af:applications.email_confirmation_modal.email_sent.subtitle',
              {
                email: _vm.email,
              }
            )
          )}})]),_c('img',{staticClass:"mx-auto is-block",attrs:{"src":require('@/assets/images/mail-sent.svg'),"alt":"mail-sent"}}),_c('div',{staticClass:"has-text-centered"},[_c('b-button',{staticClass:"mt-4",attrs:{"type":"is-text"},on:{"click":_vm.refreshPage}},[_vm._v(" "+_vm._s(_vm.$t( "af:applications.email_confirmation_modal.confirmed_on_another_device.button" ))+" ")])],1)]):_vm._e()]),_c('div',{staticClass:"close-btn cursor-pointer",attrs:{"id":"emailConfirmationModalClose"},on:{"click":_vm.cancelModal}},[_c('b-icon',{attrs:{"icon-pack":"fas","icon":"times","type":"is-grey-icon"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }