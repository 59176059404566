<template>
  <div class="af-modal-card" :style='{"width":"650px"}' :class="brand">
    <div class="modal-card__content">
      <div v-if="isEmailExists && !newCodeSent">
        <p class="has-text-weight-bold is-size-4 mb-4">
          {{ $t("af:applications.email_confirmation_modal.email_exist.title") }}
        </p>

        <p>
          <span
            v-html="
              $t(
                'af:applications.email_confirmation_modal.email_exist.subtitle',
                {
                  email: email,
                }
              )
            "
          ></span>
        </p>

        <p class="has-text-weight-bold has-text-centered mt-5">
          <b-button type="is-blue" @click="sendNewCode">
            {{
              $t(
                "af:applications.email_confirmation_modal.email_exist.button.send_new_code"
              )
            }}</b-button
          >
        </p>

        <div class="has-text-centered">
          <b-button @click="refreshPage" type="is-text" class="mt-4">
            {{
              $t(
                "af:applications.email_confirmation_modal.confirmed_on_another_device.button"
              )
            }}
          </b-button>
        </div>

        <div v-if="!changeEmail">
          <p class="has-text-weight-bold has-text-centered mt-3">
            <span
              class="af-text-button has-text-blue"
              @click="changeEmail = true"
            >
              {{
                $t(
                  "af:applications.email_confirmation_modal.email_exist.button.change_email"
                )
              }}</span
            >
          </p>
        </div>
      </div>

      <div v-if="!isEmailExists && !newCodeSent">
        <p class="has-text-weight-bold is-size-4 mb-4">
          {{
            $t("af:applications.email_confirmation_modal.email_not_exist.title")
          }}
        </p>

        <p>
          {{
            $t(
              "af:applications.email_confirmation_modal.email_not_exist.subtitle"
            )
          }}
        </p>
      </div>

      <div v-if="changeEmail && !newCodeSent" class="mt-5">
        <form autocomplete="new-password">
          <validation-observer ref="observer">
            <BInputWithValidation
              rules="required|email"
              :label="
                $t(
                  'af:applications.email_confirmation_modal.change_email.email.label'
                )
              "
              label-position="on-border"
              v-model="newEmail"
              name="application-email"
            />

            <ValidationProvider
              rules="required"
              v-slot="{ errors, validated, valid }"
            >
              <b-field
                :label="
                  $t(
                    'af:applications.email_confirmation_modal.change_email.password.label'
                  )
                "
                label-position="on-border"
                name="application-code"
                class="mb-5"
                :type="{
                  'is-danger': errors[0],
                  'is-success': validated && valid,
                }"
                :message="errors"
              >
                <b-input
                  type="password"
                  autocomplete="new-password"
                  password-reveal
                  v-model="password"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </validation-observer>
        </form>
        <p class="has-text-weight-bold has-text-centered mt-5">
          <b-button
            type="is-blue"
            id="emailConfirmationModalSendNewCode"
            @click="sendNewCodeToNewEmail"
          >
            {{
              $t(
                "af:applications.email_confirmation_modal.change_email.button.submit"
              )
            }}
          </b-button>
        </p>
      </div>

      <div v-if="newCodeSent">
        <p class="has-text-weight-bold is-size-4 mb-4">
          {{ $t("af:applications.email_confirmation_modal.email_sent.title") }}
        </p>

        <p>
          <span
            v-html="
              $t(
                'af:applications.email_confirmation_modal.email_sent.subtitle',
                {
                  email: email,
                }
              )
            "
          ></span>
        </p>

        <img
          class="mx-auto is-block"
          :src="require('@/assets/images/mail-sent.svg')"
          alt="mail-sent"
        />

        <div class="has-text-centered">
          <b-button @click="refreshPage" type="is-text" class="mt-4">
            {{
              $t(
                "af:applications.email_confirmation_modal.confirmed_on_another_device.button"
              )
            }}
          </b-button>
        </div>
      </div>
    </div>

    <div
      class="close-btn cursor-pointer"
      id="emailConfirmationModalClose"
      @click="cancelModal"
    >
      <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
    </div>
  </div>
</template>

<script>
import { generateNonce } from "@/utils/util";
import i18n from "@/plugins/i18n";
import { UI_CONFIGS } from "@/utils/const";
import OfferAcceptanceModal from "@/components/offers/OfferAcceptanceModal.vue";
import MortgageOfferAcceptanceModal from "@/components/offers/MortgageOfferAcceptanceModal.vue";

export default {
  name: "EmailConfirmationModal",
  props: {
    currentEmail: {
      type: [String, undefined],
      required: false,
    },
    offerId: {
      type: Number,
      required: true,
    },
    mortgage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
  },
  data() {
    return {
      newCodeSent: false,
      changeEmail: false,
      email: "",
      newEmail: "",
      password: "",
    };
  },
  computed: {
    isEmailExists() {
      return !!this.currentEmail;
    },
    brand() {
      return this.$store.getters["config/getAutoloadConfigValue"](
        UI_CONFIGS.APPEARANCE
      );
    },
  },
  async created() {
    this.email = this.currentEmail;
    this.changeEmail = !this.isEmailExists;
  },
  methods: {
    async refreshPage() {
      const emailConfirmed = await this.$store.dispatch(
        "profile/isEmailConfirmed"
      );
      if (
        !this.$store.getters["session/isLoggedIn"] ||
        emailConfirmed.isEmailConfirmed
      ) {
        if (this.mortgage) {
          this.$buefy.modal.open({
            parent: this,
            component: MortgageOfferAcceptanceModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            props: {
              offerId: this.offerId,
            },
          });
        } else {
          this.$buefy.modal.open({
            parent: this,
            component: OfferAcceptanceModal,
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
            props: {
              offerId: this.offerId,
            },
          });
        }
        this.$parent.close();
      } else {
        this.$buefy.toast.open({
          message: i18n.t(
            "af:applications.email_confirmation_modal.confirmed_on_another_device.fail"
          ),
          type: "is-danger",
        });
      }
    },
    cancelModal() {
      this.$parent.close();
    },
    async sendNewCodeToNewEmail() {
      let valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }

      try {
        await this.$store.dispatch("profile/emailChange", {
          nonce: generateNonce(),
          email: this.newEmail,
          password: this.password,
        });
        this.email = this.newEmail;
        this.changeEmail = false;
        this.newCodeSent = true;
      } catch (ex) {
        if (ex.response.status === 409) {
          this.$buefy.toast.open({
            message: i18n.t(
              "af:applications.email_confirmation_modal.change_email.message.email_exist"
            ),
            type: "is-danger",
          });
        }
        if (ex.response.status === 406) {
          this.$buefy.toast.open({
            message: i18n.t(
              "af:applications.email_confirmation_modal.change_email.message.password_error"
            ),
            type: "is-danger",
          });
        }
      }
    },
    async sendNewCode() {
      await this.$store.dispatch(
        "profile/sendConfirmationEmail",
        generateNonce()
      );
      this.newCodeSent = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.modal-card {
  background: white;
  border: 1px solid $grey-lightest;
  border-radius: 24px;
  position: relative;
  padding: 1rem;
  width: 100%;
  max-height: 90vh;

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 769px) {
  .modal-card {
    padding: 48px;
  }
}
</style>
